import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Divider, Grid, Button } from '@material-ui/core';
import Player from '../../models/Player';
import { PlayerList } from '../../components/Lists';

const useStyles2 = makeStyles({
    root: {
        marginTop: 16,
    },
    button: {
        marginRight: "1rem",
        margin: "0.5rem 0"
    }
});

const MIN_PLAYERS = 2;

const DemoPickTeams = () => {
    let history = useHistory();
    const classes = useStyles2();
    const [host, setHost] = useState(false);

    let demoPlayers: Player[] = []

        const demoPlayer1: Player = {
            id: "demo-user",
            name: "Demo Host"
        }
        demoPlayers.push(demoPlayer1)

        const demoPlayer2: Player = {
            id: "2",
            name: "Demo Player 2"
        }
        demoPlayers.push(demoPlayer2)

        const demoPlayer3: Player = {
            id: "3",
            name: "Demo Player 3"
        }
        demoPlayers.push(demoPlayer3)

        const demoPlayer4: Player = {
            id: "4",
            name: "Demo Player 4"
        }
        demoPlayers.push(demoPlayer4)

    const [players , setPlayers] = useState(demoPlayers);
    const [redTeam , setRedTeam] = useState(Array<Player>());
    const [blueTeam , setBlueTeam] = useState(Array<Player>());
    const [error, setError] = useState("");

    useEffect(() => {

    }, [])

    const setRandomTeamsClicked = async () => {
        const sorted = players.sort(() => 0.5 - Math.random()); // randomly sort players
      
        const halfwayPoint: number = sorted.length / 2;
        const randomRedTeam = sorted.slice(0, halfwayPoint);
        const randomBlueTeam = sorted.slice(halfwayPoint, sorted.length);

        setBlueTeam(randomBlueTeam)
        setRedTeam(randomRedTeam)
    }

    const nextPageClicked = async () => {
        history.push(`demo-spymaster`);
    } 

    const pickRedTeam = async () => {
        setBlueTeam(blueTeam.filter(obj => obj.id !== "demo-user"))
        var x = players.find(obj => obj.id === "demo-user")
        redTeam.push(x!)
        setRedTeam(redTeam)
    }

    const pickBlueTeam = async () => {
        setRedTeam(redTeam.filter(obj => obj.id !== "demo-user"))
        var x = players.find(obj => obj.id === "demo-user")
        blueTeam.push(x!)
        setBlueTeam(blueTeam)
    }

    let statusMessage;
    if (blueTeam.length >= MIN_PLAYERS && redTeam.length >= MIN_PLAYERS)
        statusMessage = "Host can continue to next page"
    else
        statusMessage = "Need a minimum of 2 players on each team to continue."

    let nextPageButton;

    let pickRedTeamButton;
        if (blueTeam.some(p => p.id === "demo-user")) {
            pickRedTeamButton = 
                <Button onClick={pickRedTeam} variant="outlined">Pick Red Team</Button>
        } else {
            pickRedTeamButton = 
                <Button variant="outlined" disabled>Pick Red Team</Button>
        }
    
        let pickBlueTeamButton;
        if (redTeam.some(p => p.id === "demo-user")) {
            pickBlueTeamButton = 
                <Button onClick={pickBlueTeam} variant="outlined">Pick Blue Team</Button>
        } else {
            pickBlueTeamButton = 
                <Button variant="outlined" disabled>Pick Blue Team</Button>
        }

    return (
        <>
        <Grid container className={classes.root} justify="space-evenly" wrap="wrap" alignItems="stretch">
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <h4>Red Team</h4>
                    <Grid item>
                        { pickRedTeamButton }
                    </Grid>
                    <PlayerList players={redTeam}/>
                </Grid>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <h4>Blue Team</h4>
                    <Grid item>
                        { pickBlueTeamButton }
                    </Grid>
                    <PlayerList players={blueTeam}/>
                </Grid>
            </Grid>
        </Grid>
        <Grid container justify="flex-end" className={classes.root} >
        { !host && 
            <Grid item xs={12}>
                <Button onClick={setRandomTeamsClicked} variant="outlined" className={classes.button}>Randomize Teams</Button>
                { nextPageButton }
            </Grid>
        }
        { !host &&
            <Grid item xs={12}>
                <span>Players pick teams. A randomize option is available.<br></br></span>
                <span><br></br></span>
                <span>{statusMessage}</span>
            </Grid>
        }
        { error &&
            <Grid item xs={12}><span>{error}</span></Grid>
        }
            <Grid item xs={12}>
                <Button onClick={nextPageClicked} variant="outlined" className={classes.button}>Next</Button>
                { nextPageButton }
            </Grid>
        </Grid>
        </>
    )
}

export default DemoPickTeams;