import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as CONSTANTS from "../../constants";
import { makeStyles, Grid, Divider, Button } from '@material-ui/core';
import Player from '../../models/Player';
import { UserContext } from '../../components/Session';
import { PlayerListWithIcons } from '../../components/Lists';

const convertFirebasePlayerstoTeams = (fPlayers) => {
    let players: Array<Player> = [];
    for (const pid in fPlayers) {
        let player = fPlayers[pid]
        players.push({
            id: pid,
            name: player[CONSTANTS.NAME],
            team: player[CONSTANTS.TEAM],
            spymaster: player[CONSTANTS.SPYMASTER] ? player[CONSTANTS.SPYMASTER] : false
        })
    }
    return players;
}

const useStyles2 = makeStyles((theme) => ({
    root: {
        marginTop: 16,
    },
    button: {
        margin: theme.spacing(2),
    },
}));

const DemoSpyMaster = () => {
    let demoPlayers: Player[] = []

    const demoPlayer1: Player = {
        id: "demo-user",
        name: "Demo Host"
    }
    demoPlayers.push(demoPlayer1)

    const demoPlayer2: Player = {
        id: "2",
        name: "Demo Player 2"
    }
    demoPlayers.push(demoPlayer2)

    const demoPlayer3: Player = {
        id: "3",
        name: "Demo Player 3"
    }
    demoPlayers.push(demoPlayer3)

    const demoPlayer4: Player = {
        id: "4",
        name: "Demo Player 4"
    }
    demoPlayers.push(demoPlayer4)

    //let { id } = useParams();
    let history = useHistory();
    const user = useContext(UserContext);
    const [players , setPlayers] = useState(demoPlayers);
    const [redTeam , setRedTeam] = useState(Array<Player>());
    const [blueTeam , setBlueTeam] = useState(Array<Player>());
    const [loading, setLoading] = useState(false);
    const classes = useStyles2();

    useEffect(() => {
        setLoading(true);

        setRandomTeamsClicked();

        setLoading(false);
    }, [user])

    useEffect(() => {
        
    }, [players])

    const setRandomTeamsClicked = async () => {
        const sorted = players.sort(() => 0.5 - Math.random()); // randomly sort players
      
        const halfwayPoint: number = sorted.length / 2;
        const randomRedTeam = sorted.slice(0, halfwayPoint);
        const randomBlueTeam = sorted.slice(halfwayPoint, sorted.length);

        setBlueTeam(randomBlueTeam)
        setRedTeam(randomRedTeam)
    }

    const setSpymaster = () => {
        const demoPlayer1: Player = {
            id: "demo-user",
            name: "Demo Host",
            spymaster: true
        }

        if (redTeam.some(e => e.id === "demo-user")){
            setRedTeam(redTeam.map(p => {
                if (p.id === "demo-user") {
                    return { ...p, ...demoPlayer1};
                }
                return p;
            }))
        } else {
            setBlueTeam(blueTeam.map(p => {
                if (p.id === "demo-user") {
                    return { ...p, ...demoPlayer1};
                }
                return p;
            }))
        }
    }

    const unsetSpymaster = () => {
        const demoPlayer1: Player = {
            id: "demo-user",
            name: "Demo Host",
            spymaster: false
        }

        if (redTeam.some(e => e.id === "demo-user")){
            setRedTeam(redTeam.map(p => {
                if (p.id === "demo-user") {
                    return { ...p, ...demoPlayer1};
                }
                return p;
            }))
        } else {
            setBlueTeam(blueTeam.map(p => {
                if (p.id === "demo-user") {
                    return { ...p, ...demoPlayer1};
                }
                return p;
            }))
        }
    }

    const canGoToNextPage = () => {
        const blueTeamSpymasterCount = blueTeam.filter(e => e.spymaster === true).length;
        const redTeamSpymasterCount = redTeam.filter(e => e.spymaster === true).length;

        return blueTeamSpymasterCount === 1 && redTeamSpymasterCount === 1;
    }

    const startGame = async () => {
        history.push(`demo-board`);
    }

    let nextPageButton = <Button onClick={startGame} variant="contained">Next</Button>
    
    return (
        <div className={classes.root}>
            <h3>Pick Spymaster</h3>
            { loading
                ? <p>Loading...</p>
                :
                    <Grid container spacing={2} justify="space-evenly">
                        <Grid item>
                            <p>Red Team</p>
                            <PlayerListWithIcons players={redTeam} page="spymaster"/>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item>
                            <p>Blue Team</p>
                            <PlayerListWithIcons players={blueTeam} page="spymaster"/>
                        </Grid>
                        <Grid container justify="center">
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={setSpymaster}
                                size="large"
                                startIcon={<span role="img" aria-label="Person raising hand">🙋</span>}
                            >
                                Yes
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={unsetSpymaster}
                                size="large"
                                startIcon={<span role="img" aria-label="Person crossing hands">🙅</span>}
                            >
                                No
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            { (canGoToNextPage()) && <>Waiting for host...</> }
                            { (!canGoToNextPage()) &&
                            <div>
                                <>Need only 1 spymaster from each team before proceeding.</>
                                <br></br><br></br>
                                <>Once both teams have picked a spymaster, the host can start the game.</>
                                <br></br><br></br>
                            </div>
                            }
                            { nextPageButton }
                        </Grid>
                    </Grid>
            }
        </div>
    )
}

export default DemoSpyMaster;