import React, { FunctionComponent } from 'react';
import NavMenu from './NavMenu';
import { Container, makeStyles } from '@material-ui/core';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    // height - navbar - footer - margins
    minHeight: "calc(100vh - 64px - 73px - 64px)",
    margin: "32px auto"
  },
}));

type LayoutProps = {
  children: any;
}

export const Layout: FunctionComponent<LayoutProps> = (props) => {
  const classes = useStyles();

  return (
    <>
      <NavMenu />
      <Container maxWidth="lg" className={classes.root}>
        {props.children}
      </Container>
      <Footer />
    </>
  );
}
