const config = {
    apiKey: "AIzaSyBrK94cgpmBWc2TOFSMmt28EwVvfJHckJY",
    authDomain: "codenames-80031.firebaseapp.com",
    databaseURL: "https://codenames-80031.firebaseio.com",
    projectId: "codenames-80031",
    storageBucket: "codenames-80031.firebasestorage.app",
    messagingSenderId: "427698506791",
    appId: "1:427698506791:web:bbfc17597c93af17e7d202",
    measurementId: "G-74JTKV1VTB"
  };

export default config;