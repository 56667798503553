import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles2 = makeStyles({
    root: {
        marginTop: 16,
    },
    button: {
        marginRight: "1rem",
        margin: "0.5rem 0"
    }
});

const Demo = () => {
    const classes = useStyles2();
    let history = useHistory();
    
    const nextPageClicked = async () => {
        history.push(`demo-pick-teams`);
    }

    return (
        <div>
            <h1>Demo</h1>
            <p>Demo will walk you thru the following steps:</p>
            <ol>
                <li>Pick teams</li>
                <li>Spymaster</li>
                <li>Gameplay</li>
            </ol>
            <Button onClick={nextPageClicked} variant="outlined" className={classes.button}>Next</Button>
        </div>
    )
}
    

export default Demo;