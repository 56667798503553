import React, { useState, useEffect } from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import * as models from "../../models";
import Tile from '../../components/Board/Tile';

const useStyles = makeStyles({
    root: {
        marginTop: 16,
    },
    button: {
        margin: "0.5rem 0"
    }
});

interface Props {
    tiles: models.Tile[];
    currentPlayer?: models.Player;
    gameOver: boolean;
}

const DemoTileBoard = ({ tiles, currentPlayer, gameOver }: Props) => {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [isEnabled, setIsEnabled] = useState(true);

    useEffect(() => {

    }, [])

    useEffect(() => {
    }, [currentPlayer])

    const handleClick = async (index) => {
        setSelectedIndex(index);
    }

    const handleFlip = async (index) => {
        setSelectedIndex(-1);
    }

    const endTurnClicked = async () => {
    }

    return (
        <>
            <Grid container spacing={3} justify="center" className={classes.root}>
                {tiles.map((tile, index) =>
                    <Grid item key={index}>
                        <Tile
                            tile={tile}
                            selectedIndex={selectedIndex}
                            handleClick={handleClick}
                            handleFlip={handleFlip}
                            isEnabled={isEnabled}
                        />
                    </Grid>
                )}
            </Grid>
            {(isEnabled) &&
                <Grid container spacing={2} justify="center">
                    <Grid item>
                        <Button type="submit" variant="contained" size="large"
                            color="primary" className={classes.button}
                            onClick={endTurnClicked}>
                            End Turn
                        </Button>
                    </Grid>
                </Grid>
            }
        </>
    )
}

export default DemoTileBoard;