import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import WelcomeCard from '../components/General/WelcomeCard';
import { useHistory, Link } from 'react-router-dom';
import { UserContext } from '../components/Session';
import app from '../base';
import { PLAYERS, CODE } from '../constants';
import { useSnackbar } from 'notistack';
import SimpleCard from '../components/General/SimpleCard';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowWrap: "break-word"
  },
  row: {
    '& > a': {
      cursor: "pointer",
      textDecoration: "none"
    },
    alignItems: "stretch",
    display: "flex",
    flexWrap: "wrap",
    margin: "-12px -20px",
    padding: "12px 0 12px 20px"
  },
  item: {
    marginRight: 20,
    marginBottom: 20
  }
}));

const HomePage = () => {
  let history = useHistory();
  const classes = useStyles();
  const user = useContext(UserContext);
  const [currentGameCode, setCurrentGameCode] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    //https://stackoverflow.com/questions/62039217/add-buy-me-a-coffee-widget-to-react-application?rq=1
    //https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx
    const script = document.createElement('script');

    script.src = "https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js";
    script.setAttribute("data-name", "BMC-Widget");
    script.setAttribute("data-id", "dkamdar");
    script.setAttribute("data-description", "Support me on Buy me a coffee!");
    script.setAttribute("data-message", "Enjoying the game? Please support my efforts.");
    script.setAttribute("data-color", "#FF813F");
    script.setAttribute("data-position", "right");
    script.setAttribute("data-x_margin", "18");
    script.setAttribute("data-y_margin", "18");
    script.async = true;

    script.onload = function() {
      var evt = document.createEvent('Event');  
      evt.initEvent('load', false, false);  
      window.dispatchEvent(evt);
    }

    document.body.appendChild(script);
    
    if (user) {
      app.database().ref(PLAYERS).child(user.uid).child(CODE)
        .once('value', snapshot => {
          if (snapshot.exists()){
            setCurrentGameCode(snapshot.val())
          }
        });
    }

    return () => {
      document.body.removeChild(script);
      const elem = document.getElementById("bmc-wbtn");
      if (elem) {
        document.body.removeChild(elem.nextElementSibling!)
        document.body.removeChild(elem)
      }
    }
  }, [])

  const createNewRoom = async () => {
    try {
      enqueueSnackbar('Creating new game...');

      var newGame = app.functions().httpsCallable('newGame-start')
      await newGame({ playerName: user?.displayName })
        .then((result) => {
          const gameCode = result.data.code;

          if (gameCode){
            closeSnackbar();
                history.push(`/room/${gameCode}`);
          }
          else
            throw Error("No game code discovered. Please try again.")
        });
    }
    catch (err) {
        console.error(err.message);
    }
  }

  return (
    <div className={classes.root}>
      <h1>Codenames</h1>
      <p>The two rival spymasters know the secret identities of 25 agents. Their teammates know the agents only by their CODENAMES.</p>
      <p>The teams compete to see who can make contact with all of their agents first. Spymasters give one-word clues that can point to multiple words on the board. Their teammates try to guess words of the right color while avoiding those that belong to the opposing team. And everyone wants to avoid the assassin.</p>
      <p>Codenames: win or lose, it’s fun to figure out the clues.</p>
      <p><strong>Author: </strong>Vlaada Chvátil</p>
      <p><strong>Source: </strong><a href="https://czechgames.com/en/codenames/description/">https://czechgames.com/en/codenames/description/</a></p>
      { user
        ? <>
              <h2>Start</h2>
              <div className={classes.row}>
                <div className={classes.item} onClick={createNewRoom}>
                  <WelcomeCard
                    headerText={"New Game"}
                    contentText={"Start a new game and assume host responsibilites. You have the power!"}
                  />
                </div>
                <Link to="/join" className={classes.item}>
                  <WelcomeCard
                    headerText={"Join Game"}
                    contentText={"Join a game with friends. Don't get left out!"}
                  />
                </Link>
                { currentGameCode &&
                  <Link to={`/room/${currentGameCode}`} className={classes.item}>
                    <WelcomeCard
                      headerText={`Rejoin Game - ${currentGameCode}`}
                      contentText={"Accidently leave the current game? No worries, rejoin."}
                    />
                </Link>
                }
              </div>
          </>
        : <>
              <h2>Start</h2>
              <div className={classes.row}>
                <Link to="/signup" className={classes.item}>
                  <WelcomeCard
                    headerText={"Start playing"}
                    contentText={"New players can go here to start playing Codenames!"}
                  />
                </Link>
                <Link to="/login" className={classes.item}>
                  <WelcomeCard
                    headerText={"Login"}
                    contentText={"Existing players can login here"}
                  />
                </Link>
              </div>
              <h2>Demo</h2>
              <div className={classes.row}>
                <Link to="/demo" className={classes.item}>
                  <WelcomeCard
                    headerText={"Start Demo"}
                    contentText={"Demo mode"}
                  />
                </Link>
              </div>
          </>
      }
      <h2>Overview</h2>
      <p>
        <span><strong>Players: </strong>4+. </span>
        <span><strong>Age: </strong>14+. </span>
        <span><strong>Time: </strong>15 minutes. </span>
      </p>
      <h2>How to Play</h2>
      <div className={classes.row}>
        <a target="_blank" rel="noopener noreferrer" className={classes.item} href="https://czechgames.com/files/rules/codenames-rules-en.pdf">
          <SimpleCard headerText="Rules" contentText="PDF in English" />
        </a>
        <a target="_blank" rel="noopener noreferrer" className={classes.item} href="https://youtu.be/C97mCg9AxZc">
          <SimpleCard headerText="Video" contentText="YouTube" />
        </a>
      </div>
      <h2>Powered By</h2>
      <div>
        <a target="_blank" rel="noopener noreferrer" className={classes.item} href="https://reactjs.org/">
          <img height="100px" src="https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg" alt="React" />
        </a>
        <a target="_blank" rel="noopener noreferrer" className={classes.item} href="https://firebase.google.com/">
          <img height="100px" src="https://firebase.google.com/downloads/brand-guidelines/SVG/logo-built_black.svg" alt="Firebase" />
        </a>
        <a target="_blank" rel="noopener noreferrer" className={classes.item} href="https://www.typescriptlang.org/">
          <img height="100px" src="https://miro.medium.com/max/700/1*mn6bOs7s6Qbao15PMNRyOA.png" alt="TypeScript" />
        </a>
      </div>
      <h2>Version History</h2>
      <h4>1.0 - July 7, 2020</h4>
      <ul>
        <li>Game launched!</li>
      </ul>
    </div>
  );
}

export default HomePage;