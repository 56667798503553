import React, { useEffect, useState, useContext } from 'react';
import { Grid, Divider, makeStyles, Backdrop, Button, CircularProgress } from '@material-ui/core';
import { Player } from '../../models';
import Tile from '../../models/Tile';
import Turn from '../../models/Turn';
import SimpleBackdrop from '../../components/General/SimpleBackdrop';
import { UserContext } from '../../components/Session';
import { PlayerListWithIcons } from '../../components/Lists';
import DemoTileBoard from './DemoTileBoard';
import DemoSpymasterBoard from './DemoSpymasterBoard';
const data = require("../../data/demoTiles.json");

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
    },
    item: {
        margin: 10
    }
}));

const DemoBoard = () => {
    let demoPlayers: Player[] = []
    
        const demoPlayer1: Player = {
            id: "demo-user",
            name: "Demo Host",
            spymaster: true
        }
        demoPlayers.push(demoPlayer1)
    
        const demoPlayer2: Player = {
            id: "2",
            name: "Demo Player 2"
        }
        demoPlayers.push(demoPlayer2)
    
        const demoPlayer3: Player = {
            id: "3",
            name: "Demo Player 3"
        }
        demoPlayers.push(demoPlayer3)
    
        const demoPlayer4: Player = {
            id: "4",
            name: "Demo Player 4"
        }
        demoPlayers.push(demoPlayer4)

    let demoTiles: Tile[] = []

    demoTiles = data;

    // let { id } = useParams();
    const user = useContext(UserContext);
    const classes = useStyles();
    
    const [loading, setLoading] = useState(true);
    const [tiles , setTiles] = useState(demoTiles);
    const [players , setPlayers] = useState(demoPlayers);
    const [redTeam , setRedTeam] = useState(Array<Player>());
    const [blueTeam , setBlueTeam] = useState(Array<Player>());
    const [gameOver, setGameOver] = useState(false);
    const [currentPlayer, setCurrentPlayer] = useState<Player>();
    const [turn, setTurn] = useState<Turn>();
    const [winningTeam, setWinningTeam] = useState("");
    const [host, setHost] = useState(false);

    useEffect(() => {
        setRandomTeamsClicked()
        setLoading(false);
        setCurrentPlayer(demoPlayer1)
    }, [user])

    useEffect(() => {
        if (gameOver) {
            // flip tiles on client
            const flippedTiles = tiles.map(t => ({...t, flipped: true}))
            setTiles(flippedTiles);
        }
    }, [gameOver])

    const setRandomTeamsClicked = async () => {
        const sorted = players.sort(() => 0.5 - Math.random()); // randomly sort players
      
        const halfwayPoint: number = sorted.length / 2;
        const randomRedTeam = sorted.slice(0, halfwayPoint);
        const randomBlueTeam = sorted.slice(halfwayPoint, sorted.length);

        randomBlueTeam.forEach(obj => obj.team = "blue")
        randomRedTeam.forEach(obj => obj.team = "red")

        setBlueTeam(randomBlueTeam)
        setRedTeam(randomRedTeam)
    }

    const newGame = async () => {

    }

    const getStatus = () => {
        if (gameOver)
            return (
                <>
                <SimpleBackdrop>
                    <Grid container justify="center">
                        <h1>Game Over.</h1>
                        <Grid item xs={12}></Grid>
                        <h1>{winningTeam} team won!</h1>
                    </Grid>
                </SimpleBackdrop>
                { !host && <p>Game Over. Host can start a new game.</p> }
                { host &&
                    <Button variant="contained" onClick={newGame}>Start New Game</Button>
                }
                </>
            )
            
        if (turn) {
            if (turn.type === "spymaster")
                return (<p>{turn.team} spymaster is thinking...</p>)
            else if (turn.type === "player")
                return (<p>{turn.team} team is guessing. Clue: {turn!.clue}. Guesses: {turn!.guesses}</p>)
        }
    }
    
    if (!gameOver) {

    }

    const playerViewClicked = () => {
        demoPlayer1.spymaster = false
        setCurrentPlayer(demoPlayer1)

        let x: Turn = {
            clue: "city",
            guesses: 2,
            selected: 0,
            team: "red",
            type: "player"
        }
        setTurn(x);
    }

    const spyMasterViewClicked = () => {
        demoPlayer1.spymaster = true
        setCurrentPlayer(demoPlayer1)

        let x: Turn = {
            clue: "",
            guesses: 0,
            selected: 0,
            team: "red",
            type: "spymaster"
        }
        setTurn(x);
    }

    const gameOverClicked = () => {
        setGameOver(true);
    }

    const resetClicked = () => {
        setGameOver(false)
        setTiles(demoTiles);
    }

    if (loading)
        return (
            <Backdrop open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )

    return (
        <>
            <Grid container justify="center">
                <Grid item>
                    {getStatus()}
                </Grid>
            </Grid>
            { currentPlayer?.spymaster
                ? <DemoSpymasterBoard tiles={tiles} currentPlayer={currentPlayer} />
                : <DemoTileBoard tiles={tiles} currentPlayer={currentPlayer} gameOver={gameOver} />
            }
            <Grid container className={classes.root} justify="space-evenly" alignItems="stretch">
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Grid item>
                            <h4>Red Team: {tiles.filter(p => p.color === "red" && p.flipped).length} / {tiles.filter(p => p.color === "red").length}</h4>
                        </Grid>
                        <Grid item>
                            <PlayerListWithIcons players={redTeam.sort((a,b) => a===b? 0 : a.spymaster? -1 : 1)} page="board" />
                        </Grid>
                    </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Grid item>
                            <h4>Blue Team: {tiles.filter(p => p.color === "blue").filter(p => p.flipped).length} / {tiles.filter(p => p.color === "blue").length}</h4>
                        </Grid>
                        <Grid item>
                            <PlayerListWithIcons players={blueTeam.sort((a,b) => a===b? 0 : a.spymaster? -1 : 1)} page="board" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <hr></hr>
            <Grid>
                <p>Use different options below to demo the board</p>
                <Button variant="outlined" onClick={spyMasterViewClicked} className={classes.item}>Spymaster View</Button>
                <Button variant="outlined" onClick={playerViewClicked} className={classes.item}>Player View</Button>
            </Grid>
            <Grid>
                <Button variant="outlined" onClick={gameOverClicked} className={classes.item}>Game Over</Button>
                <Button variant="outlined" onClick={resetClicked} className={classes.item}>Reset</Button>
            </Grid>
        </>
    )
}

export default DemoBoard;