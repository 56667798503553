import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import SpymasterControls from '../../components/Board/SpymasterControls';
import SpymasterTile from '../../components/Board/SpymasterTile';
import * as models from "../../models";


const useStyles = makeStyles({
    root: {
        marginTop: 16,
    },
});

interface Props {
    tiles: models.Tile[];
    currentPlayer: models.Player;
}

const DemoSpymasterBoard = ({ tiles, currentPlayer }: Props) => {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(-1);

    useEffect(() => {
    }, [])

    const handleSubmit = async (clue: string, guesses: number) => {
    }

    return (
        <>
        <Grid container spacing={3} justify="center" className={classes.root}>
            {tiles.map((tile, index) =>
                <Grid item key={index}>
                    <SpymasterTile tile={tile} selectedIndex={selectedIndex} />
                </Grid>
            )}
            
        </Grid>
        <Grid container spacing={4} justify="center" className={classes.root} >
        <SpymasterControls show={true} handleSubmit={handleSubmit} />
        </Grid>
        </>
    )
}

export default DemoSpymasterBoard;